import React from "react";
import {FormattedMessage} from "react-intl";
import {useToast} from "../../../atoms/Toast/useToast";
import dynamic from "next/dynamic";
const Indicator = dynamic(() => import("./../Indicator"))
import {MenuSvg, FailSvg} from "../../../svg";
import {useSelector} from "react-redux";
import {IState} from "../../../types/state";
const FailPanel = () => {
    const toast = useToast();
    const wishlist = useSelector((state: IState) => state["wishlist"]);

    return (
        <div
            className="indicator indicator--trigger--click wishlist-a-fms"
            onClick={(e) => {
                e.preventDefault();
                toast.open(
                    <span className="d-flex faild-toast-fms">
                        <FailSvg/>
                        <FormattedMessage
                            id="please_signin_toast"
                            defaultMessage="Please sign in or register"
                        />
                    </span>
                );
            }}
        >
            <Indicator
                url=""
                value={wishlist.length}
                icon={<MenuSvg/>}
                title={<FormattedMessage
                    id="wishlist"
                    defaultMessage="Favorites"
                />}
            />
        </div>
    )
};

export default FailPanel;
