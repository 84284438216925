import React, { FC } from "react";
import Link from "next/link";
import {IIndicatorButton as IProps} from "./types";

const IndicatorButton: FC<IProps> = ({
    url,
    handleButtonClick,
    title,
    titleSpan
}) => {
    return (
        <>
            {(url && (
                <Link
                    href={url}
                    passHref
                >
                    <a
                        className="indicator__button"
                        onClick={handleButtonClick}
                    >
                        {title}
                    </a>
                </Link>
            )) || (
                <button
                    aria-label="indicatorButton"
                    type="button"
                    className="indicator__button"
                    onClick={handleButtonClick}
                >
                    {title}
                </button>
            )}
            <span
                onClick={handleButtonClick}
                className="indicator-title-fms"
            >
                    {titleSpan}
                </span>
        </>
    )
}

export default IndicatorButton;
