import React from "react";
import NavPanelChild from "./Hotel_NavPanel/index";

function NavPanel({ layout = "default" }: { layout: string, }) {

    return (
        <div className="nav-panel">
            <div className="nav-panel__container">
                <div className="nav-panel__row">
                    <NavPanelChild
                        layout={layout}
                    />
                </div>
            </div>
        </div>
    );
}


export default NavPanel;
