import React, {Fragment, FC, ReactNode} from "react"
import {FormattedMessage} from "react-intl";
import FailPanel from "./FailPanel";
import {useSelector} from "react-redux";
import {IState} from "../../../types/state";
import HotelIndicatorAccount from "../Hotel_IndicatorAccount";
import {Indicator} from "../Indicator";
import {HotelHeart} from "../../../svg";
import dynamic from "next/dynamic";
const CartIndicator = dynamic(() => import("./../IndicatorCart"))

const IndicatorPanel: FC<{searchIndicator: ReactNode}> = (
    {
        searchIndicator,
    }
) => {
    const signed = useSelector((state: IState) => state["customer"].authenticated);
    const wishlist = useSelector((state: IState) => state["wishlist"]);
    return (
        <Fragment>
            {searchIndicator}
            <div className="nav-panel__item_row my_account">
                <HotelIndicatorAccount />
            </div>
            <div className="nav-panel__item_row">
                <CartIndicator/>
            </div>
            <div className="nav-panel__item_row heartButton">
                {
                    (signed && (
                        <Indicator
                            url="/wishlist"
                            value={wishlist.length}
                            icon={<HotelHeart/>}
                            // title={<FormattedMessage id="wishlist" defaultMessage="Favorites"/>}
                        />
                    )) || (
                        <FailPanel />
                    )
                }
            </div>
        </Fragment>
    )
}

export default IndicatorPanel;
