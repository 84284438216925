import React, {Fragment, useEffect, useState} from "react";
import Link from "next/link";
import dynamic from 'next/dynamic'
import {LogoSmallSvg} from "../../../svg";
import IndicatorPanel from "./IndicatorPanel";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {setWindowSize} from "../../../store/general";
import {useDispatch, useSelector} from "react-redux";
import {IState} from "../../../types/state";
import { apiImageUrl } from "../../../helper";

const Departments = dynamic(() => import('./../Departments'))
const IndicatorSearch = dynamic(() => import("./../IndicatorSearch"))
const Search = dynamic(() => import("./../Search"))

const NavPanelChild = ({ layout }: { layout: string }) => {
    let logo: JSX.Element | null = null;
    let departments: JSX.Element | null = null;
    let searchIndicator: JSX.Element | null = null;
    const [isMobile, setIsMobile] = useState(false);
    let windowSize = useSelector((state:IState) => state.general.windowSize)
    const dispatch = useDispatch();
    const domain = useSelector((state: IState) => state.general.domain)
    const cacheVersion = useSelector((state: IState) => state.general.cashVersion)
    const loadDefaultLogo = (e: any) => {
        e.target.src = `/stores-logo.svg?v=${cacheVersion}`;
    }

    switch (layout) {
        case "compact":
            logo = (
                <div className="nav-panel__logo">
                    <Link href="/">
                        <a>
                            <LogoSmallSvg/>
                        </a>
                    </Link>
                </div>
            )
            searchIndicator = (
                <div className="nav-panel__item_row">
                    <IndicatorSearch/>
                </div>
            )
            break;
        case "default":
            departments = (
                <div className="nav-panel__departments">
                    <Departments />
                </div>
            );
    }

    useEffect(() => {
        if(!windowSize) dispatch(setWindowSize(window.innerWidth))
        setIsMobile(windowSize <= 768)
    }, [windowSize])

    return (
        <Fragment>
            {logo}
            {/*{departments}*/}
            <div className="header__logo">
                <Link href="/">
                    <a>
                        <LazyLoadImage
                            alt="logo"
                            height={!isMobile ? 45 : 20}
                            // width={"100%"}
                            src={`${apiImageUrl}/storage/${domain}/configuration/logo/logo.webp?v=${cacheVersion}`}
                            onError={loadDefaultLogo}
                        />
                    </a>

                </Link>
            </div>
            <div className="site-header__search">
                <Search context="header"/>
            </div>
            <div className="nav-panel__indicators">
                <IndicatorPanel
                    searchIndicator={searchIndicator}
                />
                {/*<div className="nav-panel__item_row">*/}
                {/*    <CartIndicator/>*/}
                {/*</div>*/}
            </div>
        </Fragment>
    )
}
export default NavPanelChild;
